import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Seo title="About Us" />
    <Container className="singleCol">
    <h1 className="my-4">About Nautilus Designs</h1>
    <p>
            Haydn S. Adams, BFA, of Nautilus Designs, has been designing and building websites since the mid '90s. For over 20 years, he has stayed on top of latest technology when it comes to modern site builds and continues to this day.
          </p>
          <p>
            Haydn Adams received his Bachelor of Fine Arts degree in Computer Arts with an emphasis in New Media from the Academy of Art University where he also taught for 13+ years. During his tenure at the University, he taught myriad of courses including web development, UX and interactive tablet publications. One of the apps he launched on the App store had over 100,000 downloads. 
          </p>
          <p>
            Haydn launched Nautilus Designs in 2005 and has never looked back. He primarily works with clients in Georgia, California and the South Pacific, including both Hawaii and the Cook Islands. 
          </p>
          <p>
            When he's not building websites for small businesses, he is teaching other designers how to code, on his sister site, <a href="https://adesignerwhocodes.com">a.Designer Who Codes</a>.
          </p>
    </Container>
  </Layout>
)

export default SecondPage
